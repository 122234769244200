/**
 * Vant封装
 * 消息提示
 */
import {
    showDialog,
    showNotify,
    showSuccessToast,
    showConfirmDialog
} from "vant";

const Lib = {
    /**
     * 信息框
     * @param message 消息内容
     * @param title 标题[可不填]
     */
    alert(message,title = null){
        let obj = {
            message,
        };
        if (title){
            obj.title = title;
        }
        return showDialog(obj);
    },

    /**
     * 弹出执行成功框
     */
    alertSuccess(msg = '') {
        showSuccessToast(msg);
    },

    /**
     * 弹出错误信息框
     * @param msg 错误消息
     * @param notify 是否显示顶部消息提示
     */
    alertError(msg,notify = true) {
        this.alert(msg,'Error');
        if (notify){
            this.notify(4,`Error:${msg}`);
        }
    },

    /**
     * 消息提示[屏幕顶部]
     * @param typeInt [消息类型] 1.普通通知 2.成功通知 3.危险/异常提示 4.错误提示
     * @param message 消息内容
     * @param timeOut 消息显示时间,默认:3000
     */
    notify(typeInt = 1,message,timeOut = 2000){
        let typeStr;
        switch (typeInt){
            case 2:
                typeStr = 'success';
                break;
            case 3:
                typeStr = 'danger';
                break;
            case 4:
                typeStr = 'warning';
                break;
            default:
                typeStr = 'primary';
                break;
        }
        showNotify ({
            type:typeStr,
            message,
            duration:timeOut,
        });
    },

    /**
     * 弹出询问框
     * @param title 标题
     * @param message 内容
     * @param confirmCallBack [确定]回调函数
     * @param cancelCallBack [取消]回调函数
     * @return {Promise<unknown>}
     */
    confirm(
        title = '',message = '',
        confirmCallBack, cancelCallBack = ()=>{},
        )
    {
        return showConfirmDialog({
            title, message,
        })
            .then(confirmCallBack)
            .catch(cancelCallBack);
    },

    /**
     * [询问框]二次确认是否执行该功能
     * @param name 功能名字
     * @param CallBack 执行回调
     */
    confirmSubmit(name,CallBack){
        this.confirm('再次确认',`是否执行[${name}]?`,CallBack);
    }


};

export default Lib;